import React, { useRef } from 'react';
import "devextreme/dist/css/dx.light.css";
import DataGrid, {Column,Selection,FilterRow,Paging,Editing,ColumnChooser,Pager,Scrolling,allowedPageSizes,SearchPanel,GroupPanel, Grouping, SortByGroupSummaryInfo, Summary,StateStoring, Lookup,} from "devextreme-react/data-grid";
import {HeaderFilter,FilterPanel,FilterBuilderPopup,Export} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from "devextreme/excel_exporter";

import { useNavigate } from 'react-router-dom';

export default function OrdersDevExpressDataGrid(props){
    const navigate = useNavigate(); 
    const dataTable = useRef(null);
    const gridHeight = props.listType==="history"? "85vh" : "100vh";

    const renderJobID = (params) => {
        if(params.value){        
            return (      
                <a style={{ color: "#1234b0" }} href={`${window.location.origin}/orderDetail/${params.value}/${props.account}`}>
                    {params.value.substring(0,10)}
                </a>
            );
        }
    };     

    const onExporting = (e) => {
        console.log("onExporting-" + e.format);
        if (e.format === "xlsx") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Orders");
            exportDataGrid({
                component: e.component,
                worksheet,
                autoFilterEnabled: true,
            }).then(() => {
                 workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "Orders.xlsx"
                    );
                });
            });
            e.cancel = true;
        } else if (e.format === "pdf") {
            const doc = new jsPDF();
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component,
            }).then(() => {
                doc.save("Orders.pdf");
            });
        } else if (e.format === "csv") {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet("Orders");
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
            }).then(function () {
                workbook.csv.writeBuffer().then(function (buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        "Orders.csv"
                    );
                });
            });
         }
    };

    const exportFormats = ["pdf", "xlsx", "csv"];

    const filterBuilderPopupPosition = {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
    };  

    const startViewDetail = (e) => {
        console.log("startViewDetail --" + JSON.stringify(e.data));
        navigate(`/orderDetail/${e.data.jobID}/${props.account}`);
    };


   return(

    <DataGrid
        className ={"gridContainer"}
        ref={dataTable}
        dataSource={props.orders}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        height = {gridHeight}
        keyExpr="id"  
        onCellDblClick ={startViewDetail} 
        onExporting={onExporting} 
        onRowPrepared={(e) => {
            if(e.data && (e.data.Status === 'Verified' ||e.data.Status === 'Delivered' ||e.data.Status === 'Invoiced'))
                e.rowElement.style.backgroundColor = "#c6ecc6";            
            else if (e.data && e.rowIndex % 2 === 0)
                e.rowElement.style.backgroundColor = "#FFFFFF";
            else if (e.data && e.rowIndex % 2 !== 0)
                e.rowElement.style.backgroundColor = "#E5E7E9";    

       }}
    >
    
    <FilterPanel visible={true} />
    <FilterBuilderPopup position={filterBuilderPopupPosition} />
    <HeaderFilter visible={true} />
    <GroupPanel visible={true} />
    <Grouping autoExpandAll={false} />
    <SearchPanel visible={true} width={240} placeholder="Search..." />
   
    <Selection mode="single" />
    <FilterRow visible={true} />
    <Paging defaultPageSize={35} /> 
    <Pager
      visible={true}
    //allowedPageSizes={[20,30, 'all']}
      allowedPageSizes={[20,35,60,'all']}
      showPageSizeSelector={true}
      showInfo={true}
      infoText="Page #{0}. Total: {1} ({2} items)"
      displayMode="full"    />

    <Scrolling rowRenderingMode="virtual"></Scrolling>
    

    <Column  dataField="jobID" caption="OrderID"  width={100} cellRender={renderJobID} />   
    <Column dataField="Status" caption="Status" width={100} alignment="center"/>  
    <Column  dataField="location" caption="Location"  width={100} alignment="center"/> 
    <Column dataField="sCompanyName" caption="From" width={180} alignment="center"/>  
    <Column dataField="cCompanyName" caption="To" width={180} alignment="center"/>  
    <Column dataField="PROOF_DEL" caption="Deli. Proof" width={130} alignment="center"/>  
    <Column dataField="dtCallIn_UTC" caption="Call Time" width={160} alignment="center"/> 
    <Column  dataField="CALLER" caption="Caller"  width={120} alignment="center"/> 
    <Column dataField="RATE" caption="Rate" width={75} alignment="center"/>  
    <Column dataField="dtDCT_UTC" caption="Due By" width={160} alignment="center"/>  
    <Column dataField="dtDelivered_UTC" caption="Deli. By" width={160} alignment="center"/>  
    <Column dataField="CUST_REF" caption="Reference" width={120} alignment="center"/> 
    <Column dataField="SER_AREA" caption="Ser.Area" width={120} alignment="center"/>  
    <Column dataField="SER_TIME" caption="Ser.Mins" width={120} alignment="center"/> 
    <Column dataField="sContact" caption="Sh.Contact" width={120} alignment="center"/> 
    <Column dataField="sPhone" caption="Sh.Phone" width={120} alignment="center"/>  
    <Column dataField="cContact" caption="Co.Contact" width={120} alignment="center"/> 
    <Column dataField="cPhone" caption="Co.Phone" width={120} alignment="center"/>  
    <Column dataField="PIECES" caption="Pieces" width={120} alignment="center"/> 
    <Column dataField="WEIGHT" caption="Weight" width={120} alignment="center"/>      
    <ColumnChooser enabled={true} mode="select" />
  
    <Export
      enabled={true}
      formats={exportFormats}
      allowExportSelectedData={false}
    />

    {/* <StateStoring enabled={true} type="localStorage" storageKey="storageOrders" /> */}
    
  
  </DataGrid>
  )
}