import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import QuickSelectOption from "./QuickSelectOption";
import SelectInput from "../inputFields/SelectInput_org";
import HttpRequest from "../../../HttpService";
import moment from "moment";
import styles from "./styles";
import "animate.css/animate.css";
import Alert from '@mui/material/Alert';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import WarningIcon from "@material-ui/icons/Warning";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import CustomIconButton from "../inputFields/CustomIconButton";
import TextInput from "../inputFields/TextInput";

import NoticeEmitter from "../../../NoticeUtil";

//function AllQuickSelect(props) {
const AllQuickSelect = (props) => {
  //checks if it is a roundtrip
  const [isRoundTrip, setRoundTrip] = useState(false);

  const [serviceList, setServiceList] = useState([]);
  const [selectedRate, setSelectedRate] = useState(null);
  const [returnTripService, setReturnService] = useState(null);
  //const [selectedReturnRate, setSelectedReturnRate] = useState(null);
  const [returnServiceList, setReturnServiceList] = useState([]);

  const [pollInterval, setPollInterval] = useState(null);

  const [nameValue, setNameValue] = useState(props.deliveryLocation.contactName);
  const [phoneValue, setPhoneValue] = useState(props.deliveryLocation.contactPhone);
  const [tempRate, seTempRate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const divRef = React.useRef();

  NoticeEmitter.addListener("Duration_KEY", (data) => {
    //console.log('Duration_KEY=============' + data)
    if (data === 0) {
      setRoundTrip(false);
      setSelectedRate(null);
      setReturnService(null);
      //setSelectedReturnRate(null);
      // NoticeEmitter.removeListener('Duration_KEY', () => {});
      //getRateChoiceList();
    }
  });

  const handleSelectRate = (event, newRate) => {
    setSelectedRate(newRate);
    console.log("=============handleSelectRate===============" + newRate);
    getReturnTripRateList(newRate);
    resetPollInterval();
  };

  const handleSelectRateDropdown = (event, newRate) => {
    console.log("------------Enter handle select:" + newRate.props.value);
    // setSelectedRate(newRate.props.value);
    // getReturnTripRateList(newRate.props.value);
    // resetPollInterval();

    if (
      (props.deliveryLocation.contactID === null ||
        props.deliveryLocation.contactName === null ||
        props.deliveryLocation.contactPhone === null) &&
      (newRate.props.value.ser_area === "A" ||
        newRate.props.value.ser_area === "H" ||
        newRate.props.value.ser_area === "Z" ||
        newRate.props.value.ser_area === "HP")
    ) {
      setAnchorEl(divRef.current);
      seTempRate(newRate.props.value);
    } else {
      setAnchorEl(null);
      event.preventDefault();

      setSelectedRate(newRate.props.value);
      getReturnTripRateList(newRate.props.value);
      resetPollInterval();
      seTempRate(null);
    }
  };

  const handleSelectedReturnServiceChange = (event, newRate) => {
    console.log("handleSelectedReturnServiceChange:" + newRate.props.value);
    setReturnService(newRate.props.value);
  };

  const handleReturnTrip = (event) => {
    setRoundTrip(!isRoundTrip);
    props.onChange({
      target: {
        name: "isRoundTrip",
        value: !isRoundTrip,
      },
    });
  };

  function resetPollInterval() {
    clearInterval(pollInterval);

    const interval = setInterval(() => {
      getRateChoiceList();
    }, 90000);
    setPollInterval(interval);
  }

  useEffect(() => {
    var returnTrip = returnTripService;

    if (!isRoundTrip) {
      returnTrip = null;
    }

    props.onChange({
      target: {
        name: "returnTripService",
        value: returnTrip,
      },
    });

    props.onChange({
      target: {
        name: "rate",
        value: selectedRate,
      },
    });
  }, [returnTripService, isRoundTrip, selectedRate]);

  useEffect(() => {
    getRateChoiceList();

    setSelectedRate(null);
    setReturnService(null);

    clearInterval(pollInterval);
    const interval = setInterval(() => {
      getRateChoiceList();
    }, 90000);
    setPollInterval(interval);
    return () => clearInterval(pollInterval);
  }, [
    props.accountNumber,
    props.pickUpLocation,
    props.deliveryLocation,
    props.readyAtDate,
    props.readyAtDateChecked,
    props.pieces,
    props.weight,
    props.declaredValue,
  ]);

  //  useEffect(() => {
  //     getReturnTripRateChoiceList();

  //  },[selectedRate, isRoundTrip, props.pickUpLocation, props.deliveryLocation]);

  function getRateChoiceList() {
    console.log(props.accountNumber + "-getRateChoice-readyAtDateChecked:" + props.readyAtDateChecked);
    console.log(props.accountNumber + "-getRateChoice-readyAtDate:" + props.readyAtDate);
    var order = {
      IMEI: props.reference,
      account: props.accountNumber,
      checkedCallTime: props.readyAtDateChecked,
      readyAtDate: props.readyAtDate,
      declaredValue: props.declaredValue,
      pieces: props.pieces,
      weight: props.weight,
      from: props.pickUpLocation,
      to: props.deliveryLocation,
      shipAddressID: props.pickUpLocation.addressID,
      conAddressID: props.deliveryLocation.addressID,
    };
    if (props.pickUpLocation.addressID === props.deliveryLocation.addressID)
      return;
    const minDateTime = moment().toDate();
    const maxDateTime = moment().add(7, "day").toDate();
    if (props.accountNumber && order.conAddressID) {
      if (order.readyAtDate === undefined || order.readyAtDate < minDateTime) {
        order.readyAtDate = nextPickUpTime().toDate();
      }
      const tempServiceList = [];
      console.log(props.accountNumber + "-getRateChoice-" + JSON.stringify(order));
      HttpRequest.getRateChoice(props.accountNumber, order).then(
        async function (body) {
          console.log("getRateChoice-" + JSON.stringify(body.data));
          await body.data.forEach((rate) => {
            const service = {
              id: tempServiceList.length,
              value: rate.Rate,
              label: rate.descr,
              ...rate,
            };
            tempServiceList.push(service);
          });
          setServiceList(tempServiceList);
        }
      );
    }
  }

  function getReturnTripRateChoiceList() {
    var returnOrder = {
      IMEI: props.reference,
      account: props.accountNumber,
      readyAtDate: null,
      checkedCallTime: true,

      pieces: props.pieces,
      weight: props.weight,
      from: props.deliveryLocation,
      to: props.pickUpLocation,
      shipAddressID: props.deliveryLocation.addressID,
      conAddressID: props.pickUpLocation.addressID,
    };

    async function getReturnRateChoice() {
      const returnTempServiceList = [];
      HttpRequest.getRateChoice(props.accountNumber, returnOrder).then(
        async function (body) {
          await body.data.forEach((rate) => {
            const service = {
              id: returnTempServiceList.length,
              value: rate.Rate,
              label: rate.descr,
              ...rate,
            };
            returnTempServiceList.push(service);
          });
          console.log("return trip", returnTempServiceList);
          setReturnServiceList(returnTempServiceList);
        }
      );
    }
    console.log("SelectedRate", selectedRate);

    if (selectedRate && selectedRate.dtdct) {
      returnOrder.readyAtDate = selectedRate.dtdct;
      returnOrder.checkedCallTime = true;
      getReturnRateChoice();
    }
  }

  function getReturnTripRateList(tripRate) {
    var returnOrder = {
      IMEI: props.reference,
      account: props.accountNumber,
      readyAtDate: null,
      checkedCallTime: true,

      pieces: props.pieces,
      weight: props.weight,
      from: props.deliveryLocation,
      to: props.pickUpLocation,
      shipAddressID: props.deliveryLocation.addressID,
      conAddressID: props.pickUpLocation.addressID,
    };

    returnOrder.readyAtDate = tripRate.dtdct;
    returnOrder.checkedCallTime = true;

    const returnTempServiceList = [];
    HttpRequest.getRateChoice(props.accountNumber, returnOrder).then(
      async function (body) {
        await body.data.forEach((rate) => {
          const service = {
            id: returnTempServiceList.length,
            value: rate.Rate,
            label: rate.descr,
            ...rate,
          };
          returnTempServiceList.push(service);
        });
        console.log("=======return trip", returnTempServiceList);
        setReturnServiceList(returnTempServiceList);
      }
    );
  }

  function nextPickUpTime() {
    var next = moment().add(5, "minute").seconds(0).milliseconds(0);
    if (next.day() === 6) next.add(2, "day").hour(7).minute(0);
    if (next.day() === 0) next.add(1, "day").hour(7).minute(0);
    if (next.hour() > 12 + 4) next.add(1, "day").hour(7).minute(0);
    if (next.hour() < 7) next.hour(7).minute(0);
    console.log("nextPickUpTime", next.toDate());
    return next;
  }

  const handleSave = (event) => {
    setAnchorEl(null);
    event.preventDefault();
    //props.onChange(event, props.service);

    setSelectedRate(tempRate);
    getReturnTripRateList(tempRate);
    resetPollInterval();

    if (!props.hasContact &&
      (props.service.ser_area === "A" || props.service.ser_area === "H" || props.service.ser_area === "Z" ||  props.service.ser_area === "HP")) {
      //Update Address Data
      props.deliveryLocation.contactName = nameValue;
      props.deliveryLocation.contactPhone = phoneValue;
      console.log(props.deliveryLocation);

      HttpRequest.addContact(props.account, props.deliveryLocation).then(
        (response) => {
          console.log("contact added", response.data[0].contactID);
          props.deliveryLocation.contactID = response.data[0].contactID;
          HttpRequest.updateAddressContact(props.account, props.deliveryLocation.addressID, response.data[0].contactID).then((res2) => {
            console.log("update address contact", res2);
          });
        }
      );
    }
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleName = (event) => {
    setNameValue(event.target.value);
  };
  const handlePhone = (event) => {
    const formattedPhone = addDashes(event.target.value);
    setPhoneValue(formattedPhone);
  };

  function addDashes(f) {
    const f_val = f.replace(/\D[^\.]/g, "");
    if (f_val.length <= 3) {
      return f_val;
    }
    if (f_val.length <= 6) {
      return f_val.slice(0, 3) + "-" + f_val.slice(3, 6);
    }
    return f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
  }

  let dateFormatted = "";
  if (props.deliveryLocation.LastDate) {
    const date = new Date(props.deliveryLocation.LastDate.split("T")[0]);
    const dateString = date.toDateString();
    dateFormatted =  dateString.split(" ")[1] + " " + dateString.split(" ")[2] + ", " + dateString.split(" ")[3];
  }

  function renderReturnTrip() {
    return (
      <div>
        <Typography variant="h6" color="primary">
          Return Delivery Service
        </Typography>
        <FormControl
          variant="outlined"
          style={{ width: "100%", maxWidth: 500 }}
        >
          <Select
            name="returnDeliveryService"
            value={returnTripService}
            onChange={handleSelectedReturnServiceChange}
          >
            {returnServiceList.map((service) => (
              <MenuItem value={service}>{service.descr}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h6" color="primary">
        Quick Select
      </Typography>
      <Box style={styles.quickSelectRow}>
        {serviceList.map((service) =>
          service.buttonShow && service.buttonImage ? (
            <QuickSelectOption
              service={service}
              currentlySelected={selectedRate}
              pickUpLocation={props.pickUpLocation}
              deliveryLocation={props.deliveryLocation}
              hasContact={
                props.deliveryLocation.contactID !== null &&
                props.deliveryLocation.contactName !== null &&
                props.deliveryLocation.contactPhone !== null
                  ? true
                  : false
              }
              onChange={handleSelectRate}
            />
          ) : null
        )}
      </Box> 
      {/* <div style={{ paddingBottom: "20px" }} /> */}
      {/* <img src="/image.jpg" alt="image" style={{ width: "70%" }}/> */}
      {/* <img src="https://directitwebicons.blob.core.windows.net/img/images/orderEntryInfoImage.png" alt="image" style={{ width: "70%" }}/> */}
      <div style={{ paddingBottom: "10px" }} />
      <div>
        <div ref={divRef}>
          <Typography variant="h6" color="primary">
            Delivery Service
          </Typography>
          <FormControl
            variant="outlined"
            style={{ width: "100%", maxWidth: 500 }}
          >
            <Select value={selectedRate} onChange={handleSelectRateDropdown}>
              {serviceList.map((service) => (
                <MenuItem value={service}>{service.descr}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Box style={{ margin: "10px" }}>
              <Box style={styles.warningBannerBox}>
                <WarningIcon color="error" fontSize="small" />
                <Typography color="error">
                  Please provide contact name and phone:
                </Typography>
              </Box>

              <Typography variant="h6">
                {props.deliveryLocation.companyName}
              </Typography>
              {props.deliveryLocation.suite !== "" ? (
                <Typography>
                  Suite {props.deliveryLocation.suite},{" "}
                  {props.deliveryLocation.address},{" "}
                  {props.deliveryLocation.quad}
                </Typography>
              ) : (
                <Typography>
                  {props.deliveryLocation.address},{" "}
                  {props.deliveryLocation.quad}
                </Typography>
              )}

              <Box>
                <Typography style={{ display: "inline" }}>
                  {props.deliveryLocation.city}, {props.deliveryLocation.prov},{" "}
                  {props.deliveryLocation.country},{" "}
                  {props.deliveryLocation.postal}
                </Typography>
                <Tooltip
                  title={
                    <div style={{ fontSize: 15 }}>
                      {props.deliveryLocation.addressID}
                    </div>
                  }
                  placement="top"
                  arrow
                >
                  <LocationOnIcon style={styles.inlineIcon} />
                </Tooltip>
              </Box>

              <Box>
                <Typography style={{ fontWeight: "bold", display: "inline" }}>
                  Last delivery completed:{" "}
                </Typography>
                <Typography style={{ display: "inline" }}>
                  {dateFormatted}
                </Typography>
                <Tooltip
                  title={
                    <div
                      style={{ fontSize: 15 }}
                    >{`${props.deliveryLocation.lat}, ${props.deliveryLocation.long}`}</div>
                  }
                  placement="top"
                  arrow
                >
                  <GpsFixedIcon style={styles.inlineIcon} />
                </Tooltip>
              </Box>

              <Box style={styles.root}>
                <TextInput
                  type="name"
                  inputType="text"
                  label="Name"
                  name="name"
                  variant="outlined"
                  size="small"
                  color={props.hasContact ? "primary" : "secondary"}
                  onChange={handleName}
                  labelSize={3}
                  defaultValue={props.deliveryLocation.contactName}
                />
                <TextInput
                  type="phone"
                  inputType="text"
                  label="Phone"
                  name="phone"
                  variant="outlined"
                  size="small"
                  color={props.hasContact ? "primary" : "secondary"}
                  onChange={handlePhone}
                  labelSize={3}
                  defaultValue={props.deliveryLocation.contactPhone}
                />
                <CustomIconButton
                  icon="DoneIcon"
                  color={props.hasContact ? "primary" : "secondary"}
                  onClick={handleSave}
                />
              </Box>
            </Box>
          </Popover>
        </div>

        <div style={{ paddingBottom: "10px" }} />

        <FormControlLabel
          control={
            <Checkbox
              checked={isRoundTrip}
              onClick={handleReturnTrip}
              color="primary"
            />
          }
          label="RETURN/ROUND TRIP"
        />
        <div style={{ paddingBottom: "10px" }} />

        {isRoundTrip && renderReturnTrip()}

        <div style={{ paddingBottom: "10px" }} />
        {selectedRate!== null && selectedRate?.clientMessage!==''&& <Alert style={{ width: "50%" }} severity="error" >{selectedRate?.clientMessage}</Alert>}
        <div style={{ paddingBottom: "10px" }} />
        <Button
          variant="contained"
          color="primary"
          endIcon={
            <KeyboardArrowRightIcon
              style={{ verticalAlign: "middle", transform: "scale(1.5)" }}
            />
          }
          onClick={props.nextPanel}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AllQuickSelect;
