import React from "react";
import { Container, Grid } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import DoneIcon from "@material-ui/icons/Done";
import { Button, Tooltip } from "@material-ui/core";
import AddressPanel from "./components/addressPanel";
import PackageInfo from "./components/packageOptions/PackageInfo";
import PackageOptionsDetails from "./components/packageOptions/PackageOptionsDetails";
import AllPackageOptions from "./components/packageOptions/AllPackageOptions";
import SelectInput from "./components/inputFields/SelectInput_org";
import RadioWithDateSelect from "./components/pickUpDeliveryTime/RadioWithDateSelect";
import QuickSelect from "./components/pickUpDeliveryTime/QuickSelect";
import CustomDialog from "./components/dialog/Dialog";
import OrderInfo from "./components/orderInfo";
import MapContainer from "./components/mapContainer";
import "./NewOrder.css";
import CheckIcon from "@material-ui/icons/Check";
import httpRequest from "../HttpService";
import "animate.css/animate.css";
import Barcode from "react-barcode";
import PrintIcon from "@material-ui/icons/Print";
import Copyright from "../Copyright";
import copy from "copy-to-clipboard";
import CopyIcon from "@material-ui/icons/FileCopy";
import { JSPrintManager, WSStatus, ClientPrintJob,InstalledPrinter } from 'jsprintmanager';
import ReactToPrint from "react-to-print";
import PrintPanel from "./components/printPanel";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CustomInput from "./components/inputFields/CustomInput";
import NoticeEmitter from "../NoticeUtil";
import CameraButton from "./components/cameraButton";
import Gallery from "./components/gallery";

class NewOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: 1,
      completed: 0,
      accountNumber:localStorage.getItem("current_accountNumber") !== ""? localStorage.getItem("current_accountNumber"):localStorage.getItem("accountNumber"),
      pickUpLocation: {},
      deliveryLocation: {},
      name: null,
      packageSize: "",
      packageDesc: "",
      pieces: 0,
      weight: 0,
      declaredValue: 0,
      reference: "",
      instructions: "",
      deliveryEmail: "",
      additionalEmail: "",
      drNum: "",
      FedexTrackNum: "",
      readyAtDateChecked: false,
      checkedEmail: false,
      signatureRequired: true,
      pictureRequired: false,
      openHideDialog: false,
      openChangePickupDialog: false,
      mapButtonText: "StreetView",
      mapType: "directions",
      selectPrinter: null,
      printsList: [],
      lastOrder: {},
      IsPrint: false,
      duration: -1,
      durationText: "",
      intervalId: 0,
      mapURL: "",
      IsUpdateMap: false,
      accountAddress: {},
      IsNewCaller: false,
      referenceList: [],
      inputReferences: {},
      PackageOptionList: [],
      AccountServiceNotes: [],
      coContactName: null,
      coContactPhone: null,
      shContactName: null,
      shContactPhone: null,
      galleryVisible: false,
      uploadFiles: [],
      IsCreatingOrder: false,
    };
  }

  componentDidMount = () => {
    console.log("accountNumber is " + this.state.accountNumber);
    if (this.state.accountNumber === null || this.state.accountNumber === "")
      window.location.href = "/";
    try {
      var IsEmbbed = localStorage.getItem("IsEmbedded");
      this.setState({ IsEmbbed: IsEmbbed !== null && IsEmbbed === "yes" });

      //if(JSPrintManager ===  undefined)  console.log(" JSPM is :" + undefined);
      //if(JSPrintManager ===  undefined)  console.log(" JSPM.JSPrintManager is :" + undefined);   
      try{
        // JSPM.JSPrintManager.license_url =  "https://wdweb5apiprod.azurewebsites.net/jspm";
        // JSPrintManager.license_url =  "http://localhost:6655/jspm";
         JSPrintManager.auto_reconnect = false;
         JSPrintManager.start();
         JSPrintManager.WS.onStatusChanged = () => {
          if (JSPrintManager.websocket_status === WSStatus.Open) {
            JSPrintManager.getPrintersInfo().then(async (printersList) => {
                  let printers =  printersList.map((item,i) => {
                  return {value: i, label: item.name};
                });
                this.setState({ printsList: printers});
                console.log(" Printer list:" + JSON.stringify(printers));
                localStorage.setItem("PrintList", JSON.stringify(printers));

                var defaultPrinter = printers.find(function (obj) {
                  return obj.label.includes("ZDesigner");
                });
                if (defaultPrinter === undefined)
                  this.setState({ selectPrinter: printers[0].label });
                else{
                  var index = printers.findIndex(x => x.label ===defaultPrinter.label);   
                  this.setState({ selectPrinter: printers[index].label });       
                } 
                console.log("defaultPrinter :" + this.state.selectPrinter);
            });
        }
      };
    }
    catch (e) {
      console.log("componentDidMount  JSPrintManager--- exception", e);
    }

      var userAddress = localStorage.getItem("userAddress");
      if (userAddress !== "") {
        var defaultAddress = JSON.parse(userAddress);
        //console.log("retrieved defaultAddress is " + JSON.stringify(defaultAddress));
        this.setState({ accountAddress: defaultAddress });
      }

      var pre_orderRef = localStorage.getItem("previousOrderRef");
      if (pre_orderRef)  console.log("retrieved pre_orderRef is " + pre_orderRef);

      this.getReferenceList();
      this.getPackageOptions();

      var pre_orderStr = localStorage.getItem("previousOrder");
      if (pre_orderStr && pre_orderStr !== "") {
        console.log("retrieved previousOrder is " + pre_orderStr);
        var pre_order = JSON.parse(pre_orderStr);
        this.setState({ drNum: pre_order.drNum });
      }
      this.getAccountNotes();      
    } catch (e) {
      console.log("componentDidMount --- exception", e);
    }
    console.log("componentDidMount ---", this.state.accountNumber);
  };

  toggleGallery = (visible) => {
    this.setState({ galleryVisible: visible });
  };

  getAccountNotes = () => {
    const tempList = [];
    httpRequest.getAccountServiceNotes(this.state.accountNumber).then((body) => {
        body.data.forEach((item) => {
          tempList.push({
            ...item,
            id: tempList.length,
          });
        });
        this.setState({ AccountServiceNotes: tempList });
      });
  };

  getPackageOptions = () => {
    let order = {};
    httpRequest.getPackageChoice(this.state.accountNumber, order).then((body) => {
        //console.log( "getPackageChoice-" + JSON.stringify(body.data));        
        let tempOptionList =  body.data.map((option,index) => {
          option.id = index;    
          return option;
        });
        this.setState({ PackageOptionList: tempOptionList });
      });
  };

  getReferenceList = () => {
    httpRequest.getReferenceFields(this.state.accountNumber).then((body) => {
      console.log("getReferenceFields:" + JSON.stringify(body.data));     
      var pre_orderRef = localStorage.getItem("previousOrderRef");
      console.log("previousOrderRef:" + pre_orderRef);
      if (pre_orderRef && pre_orderRef !== "") {
        const tempRefList = [];
        const refInput = JSON.parse(pre_orderRef);
        body.data.forEach((item) => {
          if (refInput.hasOwnProperty(item.refID.toString())) {
            item.defaultValue = refInput[item.refID.toString()];
            this.setRefValue(item.refID, refInput[item.refID.toString()]);
          }
          if (item.typeDesc === "DropDown") {
            item.ddValues = [];
            this.getReferenceDropDown(item.refID);
          }
          if (item.typeDesc === "CheckBox") {
            item.defaultValue = refInput[item.refID.toString()];
            //item.defaultValue = item.defaultValue === '0'? false: true;
            this.setRefValue(item.refID, item.defaultValue);
          }
          tempRefList.push(item);
        });
        this.setState({ referenceList: tempRefList });
      } else {
        body.data.forEach((item) => {
          if (item.typeDesc === "DropDown") {
            item.ddValues = [];
            this.getReferenceDropDown(item.refID);
          }
          if (item.typeDesc === "CheckBox") {
            item.defaultValue = item.defaultValue === "0" ? false : true;
            this.setRefValue(item.refID, item.defaultValue);
          }
        });
        this.setState({ referenceList: body.data });
      }
    });
  };

  getReferenceDropDown = (id) => {
    httpRequest.getReferenceDropDown(id).then((response) => {
      //console.log("getReferenceDropDown:" + JSON.stringify(response.data))
      const newRows = [];
      response.data.map((item) => {
        newRows.push({
          ...item,
          id: newRows.length,
        });
      });
      let refList = this.state.referenceList;
      var objIndex = refList.findIndex((obj) => obj.refID == id);
      console.log("getReferenceDropDown:- refList index is" + objIndex);
      refList[objIndex].ddValues = newRows;
      this.setState({ referenceList: refList });
    });
  };

  postReferences = (jobID) => {
    if (JSON.stringify(this.state.inputReferences) !== "{}") {
      console.log("postReferenceFields- " + JSON.stringify(this.state.inputReferences));
      for (let key in this.state.inputReferences) {
        if (this.state.inputReferences.hasOwnProperty(key)) {
          var reference = {
            refID: key,
            value: this.state.inputReferences[key],
          };
          httpRequest.postReferenceFields(jobID, reference).then((body) => {
              console.log("postReferenceFields- " + JSON.stringify(body));
            },
            function errorCallback(err) {
              console.log("Failed to update reference editTripService field.");
            }
          );
        }
      }
    }
  };

  IsRequiredRefFilled = (requiredRefenceList) => {
    if(requiredRefenceList.length === 0)
      return true;
    if(requiredRefenceList.length === Object.keys(this.state.inputReferences).length)
      return true;

    let res = true;  
    requiredRefenceList.forEach((item) => {
      if (!this.state.inputReferences.hasOwnProperty(item.refID.toString())) {
        res = false;
      }
    });
    return res;
  }

  setRefValue = (name, value) => {
    //console.log(name + " ******setRefValue *****" + value);
    if(value === ""){
      let currentRef = this.state.inputReferences;
      delete currentRef[name];
      this.setState({
        inputReferences: currentRef
      });
    }
    else{
      this.setState({
        inputReferences: {
          ...this.state.inputReferences,
          [name]: value,
        },
      });
    }
  };
  
  changePanel = (panel) => (event, isExpanded) => {
    if (this.state.expanded === panel) {
      this.setState({ expanded: 0 });
    } else if (panel <= this.state.completed + 1) {
      this.setState({ expanded: panel });
    }
  };
  
  nextPanel = (panel) => (event, isExpanded) => {
    if (panel == 3) {
      let alertMsg = "";
      if (this.state.name == null) {
        alertMsg += "Your Name,";
      }
      if (this.state.packageDesc == "") {
        alertMsg += "Package,";
      }
      let requiredRefenceList = this.state.referenceList.filter(function (obj) {
        return obj.required;
      });
      console.log("requiredRefenceList length is " + requiredRefenceList.length);
      console.log("inputReferences length is " + Object.keys(this.state.inputReferences).length);
      //if (requiredRefenceList.length !== 0 && requiredRefenceList.length > Object.keys(this.state.inputReferences).length)
      if(!this.IsRequiredRefFilled(requiredRefenceList))
        alertMsg += "Reference,";

      if (alertMsg !== "") {
        this.setState({
          requiredFieldMsg: "please select or fill " + alertMsg,
        });
        this.setState({ openRequiredFieldDialog: true });
        return;
      }
    }
    if (panel == 4) {
      console.log("rate is " + JSON.stringify(this.state.rate));
      console.log("return rate is " + JSON.stringify(this.state.returnTripService));
      let alertMsg = "";
      if (!this.state.rate) alertMsg += "service,";
      if (this.state.isRoundTrip && !this.state.returnTripService)
        alertMsg += "return service,";
      if (alertMsg !== "") {
        this.setState({ requiredFieldMsg: "please select " + alertMsg });
        this.setState({ openRequiredFieldDialog: true });
        return;
      }
    }
    console.log("moving to ", panel);
    this.setState({ expanded: panel });
    this.setState({ completed: Math.max(panel - 1, this.state.completed) });
    this.setState({ IsUpdateMap: false });
  };


  handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    const { name, value } = event.target;
    this.setState({ [name]: value });

    if (event.target.name === "name" && event.target.value === "select") {
      this.setState({ name: null });
    }
    if (event.target.name === "rate" && event.target.value && event.target.value !== null) {
      this.setState({ duration: 1 });
      this.setState({ durationText: "1:30" });
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
        this.setState({ intervalId: 0 });
        //return;
      }
      const newIntervalId = setInterval(() => {
        if (this.state.duration === 90) {
          this.setState({ duration: -1 });
          clearInterval(this.state.intervalId);
        } else {
          this.setState({ duration: this.state.duration + 1 });
          var duration = 90 - this.state.duration;
          var minute = this.pad2(duration / 60);
          var second = this.pad2(duration % 60);
          this.setState({ durationText: minute + ":" + second });
          this.setState({ IsUpdateMap: false });
          NoticeEmitter.emit("Duration_KEY", duration);
        }
      }, 1000);
      this.setState({ intervalId: newIntervalId });
    }
  };

  handlePrintChange = (event) => {
    console.log(event.target.name, event.target.value);
    const { name, value } = event.target;
    this.setState({
      selectPrinter: this.state.printsList[parseInt(value)].label,
    });
    console.log("selectedPrinter---", this.state.selectPrinter);
  };

  handleAccountChange = (event) => {
    console.log(event.target.name, event.target.value);
    const { name, value } = event.target;
    this.setState({ [name]: value });
    console.log("handleAccountChange---", value);
    httpRequest.getAccountDetail(value).then(async function (body) {
      console.log("getAccountDetail:" + JSON.stringify(body.data));
      console.log("getAccountDetail user:" + JSON.stringify(body.data.user));
      localStorage.setItem("current_accountNumber", body.data.user.accountNumber);
      //if(body.data.user.groupID !== "")
      //   localStorage.setItem('groupID', body.data.user.groupID);
      localStorage.setItem("current_addressID",body.data.user.address.addressID);
      localStorage.setItem("current_HasInvoicePassword", body.data.user.HasInvoicePassword);
      localStorage.setItem("current_CompanyID",body.data.preferences.CompanyID);

      httpRequest.getAddressByID(body.data.user.accountNumber,body.data.user.address.addressID).then(async (body) => {
          console.log("getAddressByID:" + JSON.stringify(body.data[0]));
          localStorage.setItem("userAddress", JSON.stringify(body.data[0]));
        });
    });
    this.setState({ expanded: 1 });
    this.setState({ completed: 0 });
    this.setState({ duration: -1 });
    this.setState({ durationText: "" });
    if (this.state.intervalId) 
      clearInterval(this.state.intervalId);
     
  };

  handleFedexTrackNumChange = (event) => {
    console.log(event.target.name, event.target.value);
    this.handleChange({
      target: {
        name: "URL",
        value: event.target.value,
      },
    });
  };

  deleteLocation = () => {
    console.log("delete");
    this.setState({ openHideDialog: false });
  };

  openDialog = (name) => {
    console.log("openDialog", name);
    this.setState({ [name]: true });
  };
  closeDialog = (name) => {
    console.log("closeDialog", name);
    this.setState({ [name]: false });
  };

  handleAddressChange = (address, type, disable = false) => {
    if (type === "consignee") {
      this.setState({ deliveryLocation: address });
      this.setState({ coContactName: null });
      this.setState({ coContactPhone: null });
    } else {
      if (!disable && this.state.pickUpLocation !== undefined &&
        address.addressID !== this.state.pickUpLocation.addressID
      ) {
        this.openDialog("openChangePickupDialog");
      }
      this.setState({ pickUpLocation: address });
      this.setState({ shContactName: null });
      this.setState({ shContactPhone: null });
    }
    this.setState({ IsUpdateMap: true });
  };

  renderHeader = (num) => {
    if (num <= this.state.completed && num !== this.state.expanded) {
      return (
        <span class="di_badge_check">
          <CheckIcon style={{ marginTop: "3px" }} />
        </span>
      );
    } else {
      return <span class="di_badge">{num}</span>;
    }
  };

  pad2 = (number) => {
    number = (number < 10 ? "0" : "") + number;
    number = number.substring(0, 2);
    return number;
  };

  //submits order to the backend
  createTrip = () => {
    //console.log(this.state);
    if (this.state.IsCreatingOrder) return;
    this.setState({ IsCreatingOrder: true });
    console.log(this.state.deliveryLocation);
    if (this.state.rate) {
      let instructions = this.state.instructions;     
      let order = {
        IMEI: "000000000000000",
        account: this.state.accountNumber,
        sso_token: localStorage.getItem("sso_token"),
        deliveryEmail: this.state.deliveryEmail,
        additionalEmail: this.state.additionalEmail,
        caller: this.state.name,
        readyAtDate: this.state.readyAtDate,
        checkedCallTime: this.state.readyAtDateChecked,
        priv: "N",
        del_inst: instructions,
        packageType: this.state.packageDesc,
        pieces: this.state.pieces,
        weight: this.state.weight,
        drNum: this.state.drNum,
        FedexTrackNum: this.state.FedexTrackNum,
        URL: this.state.URL,
        from: this.state.pickUpLocation,
        to: this.state.deliveryLocation,
        checkedEmail: this.state.checkedEmail,
        cust_ref: this.state.reference,
        rate: this.state.rate,
        returnTripService: this.state.returnTripService,
        sigReq: this.state.signatureRequired,
        declaredValue:this.state.declaredValue,
        application: "webx",
        ip: localStorage.getItem("ipaddress")
      };
      console.log("order ", JSON.stringify(order));

      this.submitOrder(order);
      this.setState({ duration: -1 });
      clearInterval(this.state.intervalId);
      this.setState({ intervalId: 0 });
    }
  };

  submitOrder = async (order) => {
    console.log("submitOrder-- updated shContactName: " +this.state.shContactName);
    console.log("submitOrder-- updated shContactPhone: " +this.state.shContactPhone);
    console.log("submitOrder-- updated coContactName: " +this.state.coContactName);
    console.log("submitOrder-- updated coContactPhone: " +this.state.coContactPhone);
    if (order.from.contactID === null && order.to.contactID === null && this.state.shContactName === null && this.state.shContactPhone === null && this.state.coContactName === null &&this.state.coContactPhone === null) {
     this.doSubmitOrder(order);
    } else if (
     (this.state.shContactName !== null || this.state.shContactPhone !== null) &&
     (this.state.coContactName !== null || this.state.coContactPhone !== null)
    ) {
     console.log("add sh-contact--" + JSON.stringify(this.state.pickUpLocation))
     httpRequest.addContact(this.state.accountNumber, this.state.pickUpLocation)
       .then((body) => {
         console.log("added from/sh contact--" + JSON.stringify(body.data));
         console.log("added from/sh contact--addressID is " + this.state.pickUpLocation.addressID + 'contactID is: ' + body.data[0].contactID);
         order.from.contactID = body.data[0].contactID;
         httpRequest.updateAddressContact(this.state.accountNumber,this.state.pickUpLocation.addressID,body.data[0].contactID).then((body) => {
             console.log("updateAddressContact--" + JSON.stringify(body.data));
             console.log("add co-contact--" + JSON.stringify(this.state.deliveryLocation));
             httpRequest.addContact(this.state.accountNumber, this.state.deliveryLocation)
               .then((body) => {
                 console.log("added to/co contact--" + JSON.stringify(body.data));
                 console.log("added to/co contact--addressID is " + this.state.deliveryLocation.addressID + 'contactID is: ' + body.data[0].contactID);
                 order.to.contactID = body.data[0].contactID;
                 httpRequest.updateAddressContact(this.state.accountNumber,this.state.deliveryLocation.addressID, body.data[0].contactID).then((body) => {
                     console.log("updateAddressContact--" + JSON.stringify(body.data));
                     this.doSubmitOrder(order);
                   });
               });
           });
       });
    }else if (this.state.shContactName !== null || this.state.shContactPhone !== null  || order.from.contactID !== null) {
      console.log("add sh-contact--" + JSON.stringify(this.state.pickUpLocation));
      httpRequest.addContact(this.state.accountNumber, this.state.pickUpLocation).then((body) => {
        console.log("added from contact--" + JSON.stringify(body.data));
        console.log("added from contact--addressID is " + this.state.pickUpLocation.addressID + 'contactID is: ' + body.data[0].contactID);
        order.from.contactID = body.data[0].contactID;
        httpRequest.updateAddressContact(this.state.accountNumber,this.state.pickUpLocation.addressID,body.data[0].contactID).then((body) => {
            console.log(this.state.accountNumber + "-updateAddressContact--" + JSON.stringify(body.data));
            this.doSubmitOrder(order);
        });
      });
    }else if (this.state.coContactName !== null || this.state.coContactPhone !== null || order.to.contactID !== null) {
      console.log("add co-contact--" + JSON.stringify(this.state.deliveryLocation));
      httpRequest.addContact(this.state.accountNumber, this.state.deliveryLocation).then((body) => {
          console.log("added to contact--" + JSON.stringify(body.data));
          console.log("added to contact--addressID is " + this.state.deliveryLocation.addressID + 'contactID is: ' + body.data[0].contactID);
          order.to.contactID = body.data[0].contactID;
          httpRequest.updateAddressContact(this.state.accountNumber,this.state.deliveryLocation.addressID,body.data[0].contactID).then((body) => {
             console.log( this.state.accountNumber + "-updateAddressContact--" + JSON.stringify(body.data));
             this.doSubmitOrder(order);
          });
      });
   }
 };

  doSubmitOrder = async (order) => {
    //let body = await httpRequest.submitOrder(order).then(this.check);
    await httpRequest.submitOrder(order).then((body) => {
      console.log("submitOrder - ", JSON.stringify(body.data));
      this.setState({ orderResponse: body.data });
      console.log(this.state);
      this.setState({ expanded: 5 });
      this.setState({ completed: 4 });
      this.setState({ lastOrder: order });
      if (this.state.IsNewCaller) {
        var newCaller = {
          username: this.state.name,
          emailAddress: this.state.deliveryEmail,
        };
        httpRequest.postUserEmails(this.state.accountNumber, newCaller).then(function (body) {
            console.log("postUserEmails - ", JSON.stringify(body.data));
        });
      }
      this.postReferences(body.data.jobID);
      if (body.data.returnJobID) this.postReferences(body.data.returnJobID);
    });
  };

  startNewOrder = () => {
    let previousOrder = {
      caller: this.state.name,
      drNum: this.state.drNum,
     //from: this.state.pickUpLocation,
     //instructions: this.state.instructions,
      deliveryEmail: this.state.deliveryEmail,
      additionalEmail: this.state.additionalEmail,
      checkedEmail: this.state.checkedEmail,
    };
    localStorage.setItem("previousOrder", JSON.stringify(previousOrder));
    localStorage.setItem("previousOrderRef", JSON.stringify(this.state.inputReferences));
    this.setState({ IsCreatingOrder: false });
    //window.location.reload(false);
    window.location.href = "/neworder";
  };

  changeMapType = () => {
    if (this.state.mapType === "directions") {
      //StreeView
      this.setState({ mapButtonText: "Direction" });
      this.setState({ mapType: "place" });
    } else {
      this.setState({ mapButtonText: "StreetView" });
      this.setState({ mapType: "directions" });
    }
  };

  printOrder = () => {
    console.log("selectPrinter :" + this.state.selectPrinter);
    if (this.state.selectPrinter && this.state.selectPrinter.includes("ZPL")) {
      if (JSPrintManager.websocket_status === WSStatus.Open) {
        httpRequest.getLabelString(this.state.orderResponse.jobID).then(async (body) => {
            //console.log("getZPLLabel return-" + JSON.stringify(body.data));
            var zplLabel = body.data;
            var cpj = new ClientPrintJob();
            cpj.clientPrinter = new InstalledPrinter(
              this.state.selectPrinter
            );
            cpj.printerCommands = zplLabel.ZPLString;
            cpj.sendToClient();
          });
      }
    } else {
      this.setState({ expanded: 6 });
      this.setState({ IsPrint: true });     
    }
  };

  renderINXParts = () => {
    return (
      <div>
        <div style={{ paddingBottom: "10px" }} />
        <CustomInput
          type="number"
          icon="ClearIcon"
          inputType="number"
          label="Driver#"
          name="drNum"
          iconTooltip="Clear"
          labelSize={2}
          inputLength={3}
          onIconClick={(event) => {
            document.getElementById("drNum").value = "";
          }}
          defaultValue={this.state.drNum}
          onBlur={this.handleChange}
        />
      </div>
    );
  };

  copyTrackNumber = () => {
    copy("https://webx.directx.ca/TrackNo/" + this.state.orderResponse?.trackingNumber);
  };
  copyReturnTrackNumber = () => {
    copy("https://webx.directx.ca/TrackNo/" +  this.state.orderResponse?.returnTrackingNumber);
  };

  extendWeb = (webURL) => { window.location.href = "/web/" + webURL;};

  render() {
    return (
      <Container maxWidth="lg" class="NewOrder">
        <div id="account-picker-wrapper">
          {this.state.duration >= 0 && (
            <div id="indicator_duration">
              <CircularProgressbar
                value={this.state.duration}
                text={`${this.state.durationText}`}
                minValue="0"
                maxValue="92"
                strokeWidth={15}
                styles={buildStyles({
                  rotation: 0.25,
                  textSize: "28px",
                  pathTransitionDuration: 0.5,
                  //pathColor: `rgba(62, 152, 199, ${this.state.duration / 90})`,
                  textColor: "red",
                })}
              />
            </div>
          )}
          <div id="account-picker">
            {this.props.accountList.length>0 && <SelectInput
              optionList={this.props.accountList}
              value={this.state.accountNumber}
              name="accountNumber"
              onChange={this.handleAccountChange}
              label="ACCOUNT"
              labelSize={4}
            />}
          </div>
        </div>
        <Accordion square expanded={this.state.expanded === 1}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={this.changePanel(1)}
          >
            {this.renderHeader(1)}
            Pick Up and Delivery Locations
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item md={4}>
                {/* <div class="animate__animated animate__fadeInUp animate__delay-2s"> */}
                <div>
                  <AddressPanel
                    type="shipper"
                    onChange={this.handleAddressChange}
                    initialSelected={""}
                    onChangeContact={this.handleChange}
                    accountNumber={this.state.accountNumber}
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                {/* <div class="animate__animated animate__fadeInUp animate__delay-3s"> */}
                <div>
                  <AddressPanel
                    type="consignee"
                    onChange={this.handleAddressChange}
                    initialSelected={""}
                    onChangeContact={this.handleChange}
                    pickUpLocation={this.state.pickUpLocation}
                    accountNumber={this.state.accountNumber}
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <Grid container direction="column" justifyContent="center">
                  <Grid item>
                    <div style={{ marginLeft: "80px" }}>
                      <a href="/web/term"> Terms and Conditions </a>
                      {/* <a href='/web/covid Statement' > COVID-19 </a> */}
                      <a href="/web/privacypolicy"> Privacy Policy </a>
                    </div>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" onClick={this.changeMapType} disableFocusRipple style={{ float: "right", marginTop: "10px" }}>
                      {this.state.mapButtonText}
                    </Button>
                  </Grid>
                  <Grid item>
                    {this.state.mapType === "place" && (
                      <MapContainer
                        top={30}
                        accountNumber={this.state.accountNumber}
                        IsUpdateMap={this.state.IsUpdateMap}
                        consignAddress={this.state.deliveryLocation.companyName}
                        consignCoord={{
                          lat: this.state.deliveryLocation.lat,
                          long: this.state.deliveryLocation.long,
                        }}
                        shipperAddress={this.state.pickUpLocation.companyName}
                        shipperCoord={{
                          lat: this.state.pickUpLocation.lat,
                          long: this.state.pickUpLocation.long,
                        }}
                        type={this.state.mapType}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {this.state.mapType === "directions" &&
                      this.state.deliveryLocation.address && (
                        <MapContainer
                          top={30}
                          accountNumber={this.state.accountNumber}
                          IsUpdateMap={this.state.IsUpdateMap}
                          consignAddress={
                            this.state.deliveryLocation.address +
                            " " +
                            (this.state.deliveryLocation.quad
                              ? " " + this.state.deliveryLocation.quad + ", "
                              : "") +
                            this.state.deliveryLocation.city +
                            " " +
                            this.state.deliveryLocation.prov +
                            ", " +
                            this.state.deliveryLocation.postal
                          }
                          consignCoord={{
                            lat: this.state.deliveryLocation.lat,
                            long: this.state.deliveryLocation.long,
                          }}
                          shipperAddress={
                            this.state.pickUpLocation.address +
                            " " +
                            (this.state.pickUpLocation.quad
                              ? " " + this.state.pickUpLocation.quad + ", "
                              : "") +
                            this.state.pickUpLocation.city +
                            " " +
                            this.state.pickUpLocation.prov +
                            ", " +
                            this.state.pickUpLocation.postal
                          }
                          shipperCoord={{
                            lat: this.state.pickUpLocation.lat,
                            long: this.state.pickUpLocation.long,
                          }}
                          type={this.state.mapType}
                        />
                      )}
                  </Grid>
                  <Grid item>
                    {this.state.mapType === "directions" &&
                      this.state.deliveryLocation.address == undefined && (
                        <MapContainer
                          top={30}
                          accountNumber={this.state.accountNumber}
                          IsUpdateMap={this.state.IsUpdateMap}
                          consignAddress={
                            this.state.pickUpLocation.address +
                            " " +
                            (this.state.pickUpLocation.quad
                              ? " " + this.state.pickUpLocation.quad + ", "
                              : "") +
                            this.state.pickUpLocation.city +
                            " " +
                            this.state.pickUpLocation.prov +
                            ", " +
                            this.state.pickUpLocation.postal
                          }
                          consignCoord={{
                            lat: this.state.pickUpLocation.lat,
                            long: this.state.pickUpLocation.long,
                          }}
                          shipperAddress={
                            this.state.pickUpLocation.address +
                            " " +
                            (this.state.pickUpLocation.quad
                              ? " " + this.state.pickUpLocation.quad + ", "
                              : "") +
                            this.state.pickUpLocation.city +
                            " " +
                            this.state.pickUpLocation.prov +
                            ", " +
                            this.state.pickUpLocation.postal
                          }
                          shipperCoord={{
                            lat: this.state.pickUpLocation.lat,
                            long: this.state.pickUpLocation.long,
                          }}
                          type={this.state.mapType}
                        />
                      )}
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} style={{ marginTop: "60px" }}>
                      <Grid item md={10}>
                        <CustomInput
                          type="text"
                          icon="ClearIcon"
                          inputType="text"
                          label="Third Party URL"
                          name="FedexTrackNum"
                          iconTooltip="Clear"
                          labelSize={4}
                          // inputLength = {12}
                          onIconClick={(event) => {document.getElementById("FedexTrackNum").value = "";}}
                          onBlur={this.handleFedexTrackNumChange}
                        />
                      </Grid>
                      <Grid item md={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disableFocusRipple
                          endIcon={
                            <KeyboardArrowRightIcon
                              style={{verticalAlign: "middle",transform: "scale(1.5)",}}
                            />
                          }
                          onClick={this.nextPanel(2)}
                          disabled={!this.state.pickUpLocation.companyName ||!this.state.deliveryLocation.companyName}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={this.state.expanded === 2}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            onClick={this.changePanel(2)}
          >
            {this.renderHeader(2)}
            Package Options and Details
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" justifyContent="center">
              <Grid item sm={10} md={5}>
                {this.state.PackageOptionList.length > 0 && (
                  <AllPackageOptions
                    accountNumber={this.state.accountNumber}
                    packageSize={this.state.packageSize}
                    onChange={this.handleChange}
                    packageOptionList={this.state.PackageOptionList}
                  />
                )}
                {this.state.PackageOptionList.length > 0 && (
                  <PackageInfo
                    accountNumber={this.state.accountNumber}
                    packageOptionList={this.state.PackageOptionList}
                    packageSize={this.state.packageSize}
                    packageDesc={this.state.packageDesc}
                    pieces={this.state.pieces}
                    weight={this.state.weight}
                    onChange={this.handleChange}
                    //nextPanel={this.nextPanel(4)}
                  />
                )}
                {this.state.IsEmbbed && <this.renderINXParts />}
              </Grid>
              <Grid item sm={10} md={5}>
                <PackageOptionsDetails
                  accountNumber={this.state.accountNumber}
                  nameList={this.props.accountList}
                  onChange={this.handleChange}
                  nextPanel={this.nextPanel(3)}
                  referenceList={this.state.referenceList}
                  setRefValue={this.setRefValue}
                  accountServiceNotes={
                    this.state.IsEmbbed ? this.state.AccountServiceNotes : []
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={this.state.expanded === 3}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            onClick={this.changePanel(3)}
          >
            {this.renderHeader(3)}
            Delivery Time
          </AccordionSummary>
          <AccordionDetails>
          <Grid container spacing={2} sx={{ p: 2 }}>          
            <Grid item xs={12} md={6}> 
            <Grid container justifyContent="left" direction="column" wrap="nowrap" spacing={3} style={{ paddingLeft: "5%" }}>
              <Grid item xs={12}>
                <RadioWithDateSelect
                  header="Pick Up Now?"
                  optionOne="Yes"
                  optionTwo="No"
                  onChange={this.handleChange}
                  name="readyAtDate"
                />
              </Grid>
              {/* <Grid item sm={10} md={5}> */}
                {/* <RadioWithDateSelect header='Specify Delivery By Time?' optionOne='No' optionTwo = 'Yes' onChange={this.handleChange} name="deliveryTime"/> */}
              {/* </Grid> */}
              <Grid item xs={12}>
                {this.state.pieces > 0 && this.state.weight && (
                  <QuickSelect
                    accountNumber={this.state.accountNumber}
                    pieces={this.state.pieces}
                    weight={this.state.weight}
                    declaredValue={this.state.declaredValue}
                    pickUpLocation={this.state.pickUpLocation}
                    deliveryLocation={this.state.deliveryLocation}
                    readyAtDate={this.state.readyAtDate}
                    readyAtDateChecked={this.state.readyAtDateChecked}
                    deliveryTime={this.state.deliveryTime}
                    onChange={this.handleChange}
                    nextPanel={this.nextPanel(4)}
                  />
                )}
              </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} md={6}> 
              <img src="https://directitwebicons.blob.core.windows.net/img/images/orderEntryInfoImage.png" alt="image" style={{ width: "100%" }}/>
            </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={this.state.expanded === 4}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            onClick={this.changePanel(4)}
          >
            {this.renderHeader(4)}
            Review
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={3}>
              <Grid item sm={10} md={7}>
                <OrderInfo
                  state= {this.state}                  
                  references={this.state.inputReferences}
                  prevPanel={this.changePanel(3)}
                  nextPanel={this.nextPanel(5)}
                />
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                  <Grid item xs={12} md={12} container direction="row">
                    <Grid item xs={6}>
                      <Button variant="contained" color="primary" style={{ float: "left", margin: "2px" }}
                        startIcon={<KeyboardArrowLeftIcon style={{verticalAlign: "middle",transform: "scale(1.5)",}}/>}
                        onClick={this.changePanel(3)}
                      >
                        Go Back
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary"
                        disabled={this.state.rate === null || this.state.IsCreatingOrder}
                        style={{ float: "right", margin: "2px" }}
                        startIcon={<DoneIcon style={{verticalAlign: "middle",transform: "scale(1.5)",}}/>}
                        onClick={this.createTrip}
                        //onClick={()=>{this.toggleGallery(true)}}
                      >
                        Create Trip
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={10} md={5}>
                <MapContainer
                  top={0}
                  IsUpdateMap={this.state.IsUpdateMap}
                  consignAddress={
                    this.state.deliveryLocation.address + " " +
                    (this.state.deliveryLocation.quad? " " + this.state.deliveryLocation.quad + ", " : "") +
                    this.state.deliveryLocation.city + " " +
                    this.state.deliveryLocation.prov + ", " +
                    this.state.deliveryLocation.postal
                  }
                  consignCoord={{
                    lat: this.state.deliveryLocation.lat,
                    long: this.state.deliveryLocation.long,
                  }}
                  shipperAddress={
                    this.state.pickUpLocation.address + " " +
                    (this.state.pickUpLocation.quad? " " + this.state.pickUpLocation.quad + ", " : "") +
                    this.state.pickUpLocation.city + " " +
                    this.state.pickUpLocation.prov + ", " +
                    this.state.pickUpLocation.postal
                  }
                  shipperCoord={{
                    lat: this.state.pickUpLocation.lat,
                    long: this.state.pickUpLocation.long,
                  }}
                  type="directions"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion square expanded={this.state.expanded === 5}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
            onClick={this.changePanel(5)}
          >
            {this.renderHeader(5)}
            Order Number and Print Label
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="row" alignContent={"center"} spacing={3}>
              <Grid item xs={9}>
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography style={{ fontWeight: 500, fontSize: 16 }} variant="body1">
                          {" "}
                          Tracking Number
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                          <Grid item>
                            <Typography style={{ fontWeight: 400, fontSize: 14 }} variant="body1">
                              {this.state.orderResponse?.trackingNumber}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography style={{ fontWeight: 400, fontSize: 13 }} variant="body1">
                              https://webx.directx.ca/TrackNo/
                              {this.state.orderResponse?.trackingNumber}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="primary" style={{ float: "center", margin: "2px" }}
                          startIcon={<CopyIcon style={{verticalAlign: "middle",transform: "scale(1.5)",}}/>}
                          onClick={this.copyTrackNumber}
                        >
                          Copy Tracking Link
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {this.state.orderResponse?.returnJobID && (
                    <Grid item>
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item>
                          <Typography style={{ fontWeight: 500, fontSize: 16 }}  variant="body1">
                            Return Tracking Number
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0}>
                            <Grid item>
                              <Typography style={{ fontWeight: 400, fontSize: 14 }} variant="body1">
                                {this.state.orderResponse?.returnTrackingNumber}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography style={{ fontWeight: 400, fontSize: 13 }} variant="body1">
                                https://webx.directx.ca/TrackNo/
                                {this.state.orderResponse?.returnTrackingNumber}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Button variant="contained" color="primary" style={{ float: "center", margin: "2px" }}
                            startIcon={<CopyIcon style={{verticalAlign: "middle", transform: "scale(1.5)",}}/>}
                            onClick={this.copyReturnTrackNumber}
                          >
                            Copy Tracking Link
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    {/* {this.state.orderResponse?.jobID && <Barcode value={this.state.orderResponse?.jobID}/> } */}
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                      <Grid item xs={8}>
                        {this.state.orderResponse?.jobID && (
                          <Barcode value={this.state.orderResponse?.jobID} />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <CameraButton
                          images={this.state.uploadFiles}
                          title={"Add Files"}
                          toolTip={"Attach multimedia files or documents"}
                          onChange={this.toggleGallery}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: 16 }} variant="body1">
                      Job ID {this.state.orderResponse?.jobID} entered successfully!
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.state.orderResponse?.returnJobID && (
                      <Typography style={{ fontWeight: 600, fontSize: 16 }} variant="body1">
                        Return trip {this.state.orderResponse?.returnJobID}{" "} entered successfully!
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {this.state.selectPrinter && <SelectInput
                      optionList={this.state.printsList}
                      defaultValue ='0'
                      value = {this.state.selectPrinter.value}
                      name="selectPrinter"
                      onChange={this.handlePrintChange}
                      label="Printer"
                    />}
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" style={{ float: "center", margin: "2px" }}
                      startIcon={<PrintIcon style={{verticalAlign: "middle",transform: "scale(1.5)",}}/>}
                      onClick={this.printOrder}
                    >
                      Print Order
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" style={{ float: "center", margin: "2px" }} onClick={this.startNewOrder}>
                      New Order
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={3} justifyContent={"flex-end"} alignContent={"center"}>
                {this.state.galleryVisible && (
                  <div className="gallery">
                    <Gallery
                      jobId={this.state.orderResponse?.jobID}
                      photoArray={this.state.uploadFiles}
                      setUploadFileFlag={() => {}}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {this.state.IsPrint && (
          <Accordion square expanded={this.state.expanded === 6}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              onClick={this.changePanel(6)}
            >
              Print Order
            </AccordionSummary>
            <AccordionDetails>
              <ReactToPrint  trigger={() => <a className="a_button">PRINT</a>}   content={() => this.componentRef}/>
              <PrintPanel
                id={this.state.orderResponse?.jobID}
                ref={(el) => (this.componentRef = el)}
                account={this.state.accountNumber}
              />
            </AccordionDetails>
          </Accordion>
        )}

        {/* Custom Dialogs */}
        <CustomDialog
          open={this.state.openChangePickupDialog}
          onClose={() => this.closeDialog("openChangePickupDialog")}
          dialogTitle="Warning"
          dialogMessage="Note you are changing the pickup address."
          dialogAction={() => this.closeDialog("openChangePickupDialog")}
        />
        <CustomDialog
          open={this.state.openHideDialog}
          onClose={() => this.closeDialog("openHideDialog")}
          dialogTitle="Warning"
          dialogMessage="Address will be permanently removed from the list!"
          dialogAction={this.deleteLocation}
        />
        <CustomDialog
          open={this.state.openRequiredFieldDialog}
          onClose={() => this.closeDialog("openRequiredFieldDialog")}
          dialogTitle="Warning"
          dialogMessage={this.state.requiredFieldMsg}
        />
        
        {!this.state.IsEmbbed && (<footer><Copyright /></footer>)}      
      </Container>
    );
  }
}
export default NewOrder;
